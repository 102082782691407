import { InteractionType, RedirectRequest } from "@azure/msal-browser"
import { MsalAuthenticationTemplate, useMsal } from "@azure/msal-react"
import { navigate } from "gatsby"
import React, {
  Component,
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from "react"
import {
  QueryClient,
  QueryClientProvider,
  useMutation,
  useQueryClient,
} from "react-query"
import { toast, ToastContainer } from "react-toastify"
import { StringParam, useQueryParam } from "use-query-params"
import LoadingModal from "../Modal/LoadingModal"
import { right, saveButton } from "../Styling/Buttons"
import { patchApi, postApi } from "../Utils/WebApi-utils"
import { useForm } from "react-hook-form"
import { GetProfile } from "../Utils/ReactQueries"
import { string } from "prop-types"

export type AgreementReceiptProps = {
  productNumber: string
  amount: number
  frequency: number
  organization: string
  provider: string
}

export const MyDonorAgreementReceipt = () => {
  const { instance, accounts, inProgress } = useMsal()
  const queryClient = useQueryClient()
  const [loading, setLoading] = useState(true)
  const { handleSubmit } = useForm()
  const [webId, setWebId] = useQueryParam("webid", StringParam)
  const [org, setOrg] = useQueryParam("organization", StringParam)
  const [provider, setProvider] = useQueryParam("provider", StringParam)
  const [props, setProps] = useState<AgreementReceiptProps>({
    productNumber: "GIVER",
    amount: 0,
    frequency: 0,
    organization: "HEF",
    provider: "Vipps",
  })

  const userProfile = GetProfile(accounts, inProgress, instance)

  const Agreement = useMutation(
    async values =>
      postApi(
        process.env.GATSBY_APP_WEBAPI + "HefPayment/Agreement/Verify",
        values,
        accounts,
        inProgress,
        instance
      ).then(res => res.data),
    {
      onSuccess: (data, values) => {
        if (data.orderStatus == 292460005) {
          navigate("/")
          console.log(data)
        } else {
          setLoading(false)
          console.log(data)
          setProps({
            ...props,
            amount: data.totalAmount,
            frequency: data.paymentFrequency,
          })
        }
      },
      retry: (count, error) => {
        if (count >= 1) {
          setLoading(true)
          return false
        }
        return true
      },
    }
  )

  console.log(props)

  const agreementObject: any = {
    webId: webId,
    organization: org,
    provider: provider,
  }

  console.log(agreementObject)

  useEffect(() => {
    if (userProfile?.data != undefined && userProfile.isRefetching == false) {
      console.log(agreementObject)
      Agreement.mutate(agreementObject)
    } else if (
      userProfile?.data == undefined &&
      userProfile.isRefetching == false
    ) {
      userProfile.refetch()
    }
  }, [userProfile.isSuccess, userProfile.isRefetching])

  const createVippsAgreement = useMutation(
    async values =>
      postApi(
        process.env.GATSBY_APP_WEBAPI + "HefPayment/Agreement",
        values,
        accounts,
        inProgress,
        instance
      ).then(res => res.data),
    {
      onSuccess: (data, values) => {
        navigate(data)
      },
      onError: data => {
        toast.error("Noe gikk galt med opprettelse av vipps-avtale")
      },
      retry: (count, error) => {
        if (count >= 1) {
          setLoading(true)
          return false
        }
        return true
      },
    }
  )

  const handlePayment = () => {
    const vippsObjectAgreement: any = {
      productNumber: "GIVER",
      price: props.amount,
      frequenzy: props.frequency,
      organization: "HEF",
      provider: "Vipps",
    }
    setLoading(true)
    createVippsAgreement.mutate(vippsObjectAgreement)
  }

  return (
    <div>
      {/* <ToastContainer /> */}
      {loading ? (
        <LoadingModal />
      ) : (
        <div className="bg-gray px-8 py-8 pb-16  lg:flex lg:justify-center lg:items-center">
          <div className="lg:min-w-[50%] lg:max-w-[50%]">
            <div className="flex flex-col">
              <div>
                <h1 className="text-blue text-xl font-normal font-sans mb-8">
                  Betalingen feilet
                </h1>
              </div>
              <div className="flex flex-col mb-8">
                <div>
                  <p className="font-sans mb-2">
                    Prøv på nytt. Du kan også prøve igjen senere.
                  </p>
                </div>
                <div>
                  <p className="font-sans mb-0">
                    Hvis betalinger fortsetter å feile etter å ha prøvd senere,
                    kan du ta kontakt med banken din.
                  </p>
                </div>
              </div>
              {/* <div className="mb-2">
                <label className=" text-blue font-sans">Betalingsmetode</label>
              </div>
              <div>
                <button className=" bg-blue border-2 border-blue p-1 px-3 pt-1 pb-2 mr-4 font-sans text-white ">
                  Vipps
                </button>
              </div> */}
              <div>
                <button
                  onClick={handlePayment}
                  className={
                    " border-2 border-blue p-1 px-3 pt-1 pb-2 mt-10 font-sans text-blue hover:text-white hover:bg-100 transition-all duration-100 bg-no-repeat to-blue from-blue bg-gradient-to-t " +
                    right
                  }
                >
                  Prøv på nytt
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
